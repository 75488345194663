import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";
import { $ } from "react-jquery-plugin";

const AdminTrading = () => {
    const [userId, setUserId] = useState("");
    const [exportData, setExportData] = useState([]);
    const [allData, setallData] = useState([]);
    const [baseCurrency, setBaseCurrency] = useState("");
    const [quoteCurrency, setQuoteCurrency] = useState("");
    const [buyPrice, setBuyPrice] = useState(0);
    const [sellPrice, setSellPrice] = useState(0);
    const [Profit, setProfit] = useState(0);
    const [Quantity, setQuantity] = useState(0);
    const [currencyPairList, setCurrencyPairList] = useState([]);

    useEffect(() => {
        setProfit(parseFloat(((sellPrice - buyPrice) / Quantity)?.toFixed(5)))
    }, [sellPrice, buyPrice, Quantity]);


    const handleCurrencyPairList = async () => {
        await AuthService.getCurrencyPairList().then(async (result) => {
            if (result.success) {
                try {
                    setCurrencyPairList(result.data.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    const linkFollow = (row) => {
        return (
            <div>
                <button className="btn btn-primary btn-sm   me-2" onClick={() => { ShowSponserMapModal(row?.id) }} >Trade</button>
            </div>
        );
    };

    const ShowSponserMapModal = (id) => {
        $("#Sponser_modal").modal("show");
        setUserId(id)
    };


    const columns = [
        { name: <> User ID</>, width: "150px", selector: row => row._id, wrap: true },
        { name: <> Registration Date</>, width: "150px", sort: true, selector: row => moment(row?.createdAt).format("Do MMMM YYYY") },
        { name: <> Name</>, width: "150px", sort: true, wrap: true, selector: row => row?.firstName + row?.lastName || "----", },
        { name: <> Email</>, width: "150px", sort: true, wrap: true, selector: row => row?.emailId, },
        { name: <> Phone Number</>, width: "150px", sort: true, selector: row => row?.mobileNumber || "----", },
        { name: <> Sponser Code</>, width: "150px", sort: true, selector: row => row?.referral_code || "----", },
        { name: <> Action</>, width: "170px", selector: linkFollow, },

    ];

    useEffect(() => {
        handleExportData();
        handleCurrencyPairList();
    }, []);

    const handlePairInput = (e) => {
        const { value } = e.target
        setBaseCurrency(value.split('/')[0])
        setQuoteCurrency(value.split('/')[1])
    };

    const placeOrder = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.create_admin_trading(userId, baseCurrency, quoteCurrency, buyPrice, sellPrice, Profit, Quantity)
            if (result?.success) {
                alertSuccessMessage(result?.message)
            }
        } catch (error) {
            alertErrorMessage(error?.message);
        }
        finally { LoaderHelper.loaderStatus(false); }
    };


    const handleExportData = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.getExportList()
            if (result.success) {
                setExportData(result?.data.reverse());
                setallData(result?.data);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
        finally { LoaderHelper.loaderStatus(false); }
    };

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const filterDate = () => {
        const filteredData = allData.filter((item) => {
            const createdAtDate = new Date(item.createdAt);
            return (
                (!fromDate || createdAtDate >= new Date(fromDate)) &&
                (!toDate || createdAtDate <= new Date(toDate))
            );
        });
        setExportData(filteredData?.reverse())
    };

    const ResetfilterDate = () => {
        setFromDate('')
        setToDate('')
        setExportData(allData)
    };

    function searchObjects(e) {
        const keysToSearch = ["firstName", "lastName", "emailId", "mobileNumber", "_id"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = allData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setExportData(matchingObjects);
    };

    return <>  <div id="layoutSidenav_content">
        <main>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                <div className="container-xl px-4">
                    <div className="page-header-content pt-4">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-auto mt-4">
                                <h1 className="page-header-title">
                                    <div className="page-header-icon">
                                        <i className="fa fa-wave-square"></i>
                                    </div>
                                    Admin trading
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="container-xl px-4 mt-n10">
                <div className="filter_bar">
                    <form className="row">
                        <div className="mb-3 col ">
                            <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin"
                                name="dateFrom" value={fromDate} onChange={(e) => { setFromDate(e.target.value); }} />
                        </div>
                        <div className="mb-3 col ">
                            <input
                                type="date"
                                className="form-control form-control-solid"
                                data-provide="datepicker"
                                id="litepickerRangePlugin"
                                name="dateTo"
                                value={toDate}
                                onChange={(e) => { setToDate(e.target.value); }}

                            />
                        </div>
                        <div className="mb-3 col ">
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                                        Search
                                    </button>
                                </div>
                                <div className="col">
                                    <button
                                        className="btn btn-indigo btn-block w-100"
                                        type="button"
                                        onClick={ResetfilterDate}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between">
                        Traders Details
                        <div className="col-5">
                            <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                        </div>
                        <div className="dropdown">
                            <button
                                className="btn btn-dark btn-sm dropdown-toggle"
                                id="dropdownFadeInUp"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {" "}
                                <i className="fa fa-download me-3"></i> Export
                            </button>
                            <div
                                className="dropdown-menu animated--fade-in-up"
                                aria-labelledby="dropdownFadeInUp"
                            >
                                <CSVLink data={exportData} className="dropdown-item">
                                    Export as CSV
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive" width="100%">
                            <DataTableBase columns={columns} data={exportData} />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
        <div className="modal" id="Sponser_modal" tabindex="-1" role="dialog" aria-labelledby="Sponser_modal_modalTitle" aria-hidden="true">
            <div className="modal-dialog  alert_modal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalCenterTitle">
                            Place order
                        </h5>
                        <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" ></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group  mb-3 position-relative ">
                                <label className="small mb-1">Select Pair</label>
                                <select className="form-control  form-control-solid" onChange={handlePairInput}>
                                    <option hidden>Select Pair</option>
                                    {currencyPairList?.map((data) => {
                                        return (
                                            <option value={`${data?.base_currency}/${data?.quote_currency}`}>{data?.base_currency}/{data?.quote_currency}</option>
                                        )
                                    })}
                                </select>

                            </div>
                            <div className="form-group  mb-3 position-relative ">
                                <label className="small mb-1">Buy Price</label>
                                <input type="number" className="form-control form-control-solid" onWheel={(e) => e.target.blur()} value={buyPrice} onChange={(e) => setBuyPrice(e.target.value)} />
                            </div>
                            <div className="form-group  mb-3 position-relative ">
                                <label className="small mb-1">Sell Price</label>
                                <input type="number" className="form-control form-control-solid" onWheel={(e) => e.target.blur()} value={sellPrice} onChange={(e) => setSellPrice(e.target.value)} />
                            </div>
                            <div className="form-group  mb-3 position-relative ">
                                <label className="small mb-1">Quantity</label>
                                <input type="number" className="form-control form-control-solid" onWheel={(e) => e.target.blur()} value={Quantity} onChange={(e) => setQuantity(e.target.value)} />
                            </div>
                            <div className="form-group  mb-3 position-relative ">
                                <label className="small mb-1">Net Profit</label>
                                <input type="number" className="form-control form-control-solid" disabled onWheel={(e) => e.target.blur()} value={Profit} />
                            </div>
                            <div className="form-group  mb-3 position-relative">
                                <button className="btn btn-success   btn-block w-100" type="button" onClick={placeOrder}>
                                    Place Order
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    </>
        ;
};

export default AdminTrading;
