
import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import DataTableBase from "../../../customComponent/DataTable";

const AdminTradingList = () => {
    const [fundsDeposit, setFundsDeposit] = useState([]);
    const [allData, setAllData] = useState([]);


    const columns = [
        { name: "Date", wrap: true, selector: row => moment(row?.createdAt).format("MMM Do YYYY hh:mm A"), },
        { name: "User Id", wrap: true, selector: row => row.user_id, },
        { name: "Pair", selector: row => row.currency_pair, },
        { name: "Sell Price", selector: row => row.sell_price, },
        { name: "Buy Price", selector: row => row.buy_price, },
        { name: "Quantity", selector: row => row.quantity, },
        { name: "Net Profit", selector: row => <span className={row.net_profit < 0 ? "text-danger" : "text-success"}> {row.net_profit}</span>, },
        { name: "Status", selector: row => row.status, },
    ];

    useEffect(() => {
        handleTredingList()
    }, []);

    const handleTredingList = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.allAdminTrading().then(async result => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    setFundsDeposit(result.data?.reverse());
                    setAllData(result.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    };

    function searchObjects(e) {
        const keysToSearch = ["user_id", "currency_pair", "sell_price", "buy_price", "quantity", "net_profit", "status", "amount"];
        const searchTerm = e.target.value?.toLowerCase();
        const matchingObjects = allData?.reverse().filter(obj => { return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm)) });
        setFundsDeposit(matchingObjects?.reverse());
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fa fa-dollar-sign"></i></div>
                                        Admin Trading
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header">
                            Admin Trading
                            <div className="col-5">
                                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Export{" "}
                                </button>
                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                    <CSVLink data={fundsDeposit} className="dropdown-item">Export as CSV</CSVLink>
                                </div>
                            </div>
                        </div>
                        <div className="card-body mt-3">
                            <div className="table-responsive" width="100%">
                                <DataTableBase columns={columns} data={fundsDeposit} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AdminTradingList;