import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import TraderDetails from "../TraderDetails";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";
import { $ } from "react-jquery-plugin";

const TradeList = () => {
  const [activeScreen, setActiveScreen] = useState("userdetail")
  const [userId, setUserId] = useState("");
  const [exportData, setExportData] = useState([]);
  const [allData, setallData] = useState([]);
  const [traderData, settraderData] = useState();
  const [sponserId, setSponserId] = useState("");

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [passwords, setPassword] = useState('');
  const [signId, setSignId] = useState('')
  const [profilePic, setprofilePic] = useState("")


  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "firstName":
        setFirstName(event.target.value);
        break;
      case "lastName":
        setLastName(event.target.value);
        break;
      case "emailorphone":
        setSignId(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      case "confirmPassword":
        setprofilePic(event.target.files[0])
        break;
      default:
    }
  };

  const editProfile = async (firstName, lastName, signId, passwords, profilepicture) => {
    var formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email_or_phone", signId);
    formData.append("password", passwords);
    formData.append("profilepicture", profilepicture);
    formData.append("userId", userId);
    await AuthService.editProfile(formData).then(async result => {
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    })
  };

  const handleUserDetails = (data) => {
    console.log("🚀 ~ handleUserDetails ~ data:", data)
    setUserId()
    setFirstName()
    setLastName()
    setSignId()

    setUserId(data?._id)
    setFirstName(data?.firstName)
    setLastName(data?.lastName)
    setSignId(data?.emailId || data?.mobileNumber )
  }

  const linkFollow = (row) => {
    return (
      <div>
        <button className="btn btn-primary btn-sm   me-2" type="button" onClick={() => handleUserDetails(row)} data-bs-toggle="modal" data-bs-target="#AddBank" >Edit</button>
        <button className="btn btn-dark btn-sm   me-2" onClick={() => { settraderData(row); setUserId(row?._id); setActiveScreen("detail") }} >View</button>
        {row?.status === 'Active' ?
          <button className="btn btn-success btn-sm  me-2" onClick={() => { handleStatus(row?.id, 'Inactive') }} >Active</button>
          : <button className="btn btn-danger btn-sm  me-2" onClick={() => { handleStatus(row?.id, 'Active') }}  >Inactive</button>}
      </div>
    );
  };

  const statusAction = (row) => {
    return (
      <div>
        {row?.is_affiliate ?
          <button className="btn btn-danger btn-sm my-2 me-2" onClick={() => { handleAffiliateStatus(row?._id, false) }} > {row?.is_affiliate}Deactivate</button> :
          <button className="btn btn-success btn-sm my-2 me-2" onClick={() => { handleAffiliateStatus(row?._id, true) }} > {row?.is_affiliate}Activate</button>}
      </div>
    );
  };

  const statusActionInvester = (row) => {
    return (
      <div>
        {row?.is_investor ?
          <button className="btn btn-danger btn-sm my-2 me-2" onClick={() => { handleInvesterStatus(row?._id, false) }} > {row?.is_investor}Deactivate</button> :
          <button className="btn btn-success btn-sm my-2 me-2" onClick={() => { handleInvesterStatus(row?._id, true) }} > {row?.is_investor}Activate</button>}
      </div>
    );
  };

  const referralAction = (row) => {
    return (
      <div>
        {row?.referral_code ? <>
          <button className="btn btn-danger btn-sm me-2 my-1" onClick={() => { unMapUser(row?._id) }} > {row?.is_affiliate}Unmap</button>
          <button className="btn btn-success btn-sm  me-2" onClick={() => { ShowSponserMapModal(row?._id) }} > {row?.is_affiliate}Map</button>
        </> :
          <button className="btn btn-success btn-sm  me-2" onClick={() => { ShowSponserMapModal(row?._id) }} > {row?.is_affiliate}Map</button>
        }
      </div>
    );
  };
  const ShowSponserMapModal = (id) => {
    $("#Sponser_modal").modal("show");
    setUserId(id)
  };


  const columns = [
    { name: <> User ID</>, width: "150px", selector: row => row._id, wrap: true },
    { name: <> Registration Date</>, width: "150px", sort: true, selector: row => moment(row?.createdAt).format("Do MMMM YYYY") },
    { name: <> Name</>, width: "150px", sort: true, wrap: true, selector: row => row?.firstName + row?.lastName || "----", },
    { name: <> Email</>, width: "150px", sort: true, wrap: true, selector: row => row?.emailId, },
    { name: <> Phone Number</>, width: "150px", sort: true, selector: row => row?.mobileNumber || "----", },
    { name: <> Sponser Code</>, width: "150px", sort: true, selector: row => row?.referral_code || "----", },
    { name: <> Referral Status</>, width: "180px", wrap: true, selector: referralAction, },
    { name: <> Affiliate Status</>, width: "150px", wrap: true, selector: statusAction, },
    { name: <> Invester Status</>, width: "150px", wrap: true, selector: statusActionInvester, },
    { name: <> Action</>, width: "250px", selector: linkFollow, },

  ];

  useEffect(() => {
    handleExportData();
  }, []);

  const handleStatus = async (_id, status) => {
    await AuthService.updateStatus(_id, status).then(
      async (result) => {
        if (result.success) {
          try {
            handleExportData();
            alertSuccessMessage(result.message);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const mapUser = async (id, sponserId) => {
    try {
      LoaderHelper.loaderStatus(true)
      const result = await AuthService.mapUser(id, sponserId)
      if (result?.success) {
        handleExportData();
        setSponserId("")
        $("#Sponser_modal").modal("hide");
        alertSuccessMessage(result.message);
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      LoaderHelper.loaderStatus(false)
    }
  };

  const unMapUser = async (_id) => {
    try {
      LoaderHelper.loaderStatus(true)
      const result = await AuthService.unmapUser(_id)
      if (result?.success) {
        handleExportData();
        alertSuccessMessage(result.message);
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      LoaderHelper.loaderStatus(false)
    }
  };

  const handleInvesterStatus = async (_id, status) => {
    try {
      LoaderHelper.loaderStatus(true)
      const result = await AuthService.updateInvestorStatus(_id, status)
      if (result?.success) {
        handleExportData();
        alertSuccessMessage(result.message);
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      LoaderHelper.loaderStatus(false)
    }
  };

  const handleAffiliateStatus = async (_id, status) => {
    try {
      LoaderHelper.loaderStatus(true)
      const result = await AuthService.updateAffiliateStatus(_id, status)
      if (result?.success) {
        handleExportData();
        alertSuccessMessage(result.message);
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      LoaderHelper.loaderStatus(false)
    }
  };

  const handleExportData = async () => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.getExportList()
      if (result.success) {
        setExportData(result?.data.reverse());
        setallData(result?.data);
      }
    } catch (error) {
      alertErrorMessage(error);
    }
    finally { LoaderHelper.loaderStatus(false); }
  };

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const filterDate = () => {
    const filteredData = allData.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return (
        (!fromDate || createdAtDate >= new Date(fromDate)) &&
        (!toDate || createdAtDate <= new Date(toDate))
      );
    });
    setExportData(filteredData?.reverse())
  }
  const ResetfilterDate = () => {
    setFromDate('')
    setToDate('')
    setExportData(allData)
  };

  function searchObjects(e) {
    const keysToSearch = ["firstName", "lastName", "emailId", "mobileNumber", "_id"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData.filter(obj => {
      return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
    });
    setExportData(matchingObjects);
  }

  return activeScreen === "userdetail" ? (
    <>  <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Traders List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="filter_bar">
            <form className="row">
              <div className="mb-3 col ">
                <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin"
                  name="dateFrom" value={fromDate} onChange={(e) => { setFromDate(e.target.value); }} />
              </div>
              <div className="mb-3 col ">
                <input
                  type="date"
                  className="form-control form-control-solid"
                  data-provide="datepicker"
                  id="litepickerRangePlugin"
                  name="dateTo"
                  value={toDate}
                  onChange={(e) => { setToDate(e.target.value); }}

                />
              </div>
              <div className="mb-3 col ">
                <div className="row">
                  <div className="col">
                    <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                      Search
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-indigo btn-block w-100"
                      type="button"
                      onClick={ResetfilterDate}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between">
              Traders Details
              <div className="col-5">
                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-dark btn-sm dropdown-toggle"
                  id="dropdownFadeInUp"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  <i className="fa fa-download me-3"></i> Export
                </button>
                <div
                  className="dropdown-menu animated--fade-in-up"
                  aria-labelledby="dropdownFadeInUp"
                >
                  <CSVLink data={exportData} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase columns={columns} data={exportData} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
      <div className="modal" id="Sponser_modal" tabindex="-1" role="dialog" aria-labelledby="Sponser_modal_modalTitle" aria-hidden="true">
        <div className="modal-dialog  alert_modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Update user sponser
              </h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1">Sponser ID</label>
                  <input className="form-control  form-control-solid input-copy" type="text" Placeholder="Enter Sponser ID" value={sponserId} onChange={(e) => setSponserId(e.target.value)} />
                </div>
                <div className="form-group  mb-3 position-relative">
                  <button className="btn btn-success   btn-block w-100" type="button" disabled={!sponserId} onClick={() => mapUser(userId, sponserId)}>
                    Map User
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >


      {/* Trader Edit */}

      <div class="modal fade" id="AddBank" tabindex="-1" aria-labelledby="AddBankLaebl" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">

            <div className="modal-header">
              <h5 className="modal-title" >
                Edit User Details
              </h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"> </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row gx-3 mb-3">
                  <div className="col-md-6">
                    <label className="small mb-1" for="inputFirstName">First name </label>
                    <input type="text" className="form-control  form-control-solid" id="inputFirstName" placeholder="Enter your first name" name="firstName" value={firstName} onChange={handleInputChange} />
                  </div>
                  <div className="col-md-6">
                    <label className="small mb-1" for="inputLastNames">Last name </label>
                    <input className="form-control form-control-solid" id="inputLastNames" type="text" placeholder="Enter your last name" name="lastName" value={lastName} onChange={handleInputChange} />
                  </div>
                </div>
                <div className="row gx-3 mb-3">
                  <div className="col-md-6">
                    <label className="small mb-1" for="inputEmailAddress">Email or Phone Number</label>
                    <input className="form-control form-control-solid" id="inputEmailAddress" placeholder="Enter your email address" name="emailorphone" value={signId} onChange={handleInputChange} />
                  </div>
                  <div className="col-md-6">
                    <label className="small mb-1" for="inputLocation">Password</label>
                    <input className="form-control form-control-solid" id="inputLocation" type="text" placeholder="Enter your Password" name="password" value={passwords} onChange={handleInputChange} />
                  </div>
                </div>
                <div className="row gx-3 mb-3">
                  <div className="col-md-6">
                    <label className="small mb-1" for="inputLocation"> Profile Picture</label>
                    <input className="form-control form-control-solid" id="inputLocation" type="file" name="confirmPassword"  onChange={handleInputChange} />
                  </div>
                </div>

                <button className="btn btn-indigo" type="button" onClick={() => editProfile(firstName, lastName, signId, passwords, profilePic)}> Submit Details </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <TraderDetails userId={userId} traderData={traderData} />
  );
};

export default TradeList;
