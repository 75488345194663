import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";

const ForgetpasswordPage = () => {
    const [email, setEmail] = useState("");
    const [OTP, setOTP] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    console.log("🚀 ~ ForgetpasswordPage ~ showPassword:", showPassword)

    const navigate = useNavigate();

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "email":
                setEmail(event.target.value);
                break;
            case "otp":
                setOTP(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;
            default:
                break;
        }
    }

    const handleForgotPassword = async () => {
        LoaderHelper.loaderStatus(false);
        await AuthService.forgotPassword(email, password,+OTP).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result.message);
                    navigate('/');
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    const handleGetOtp = async () => {

        LoaderHelper.loaderStatus(true);
        await AuthService.getOtp(email, 'verification').then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
            }
        });
    };

    return (
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main className="login-card">
                    <div className="container-xl px-4">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">

                                <div className="card-body p-5 text-center">
                                    <img src="/assets/img/logo-white.png" className="img-fluid" alt="" width='300' />
                                </div>
                                <div className="card">
                                    {/* <hr className="my-0" /> */}
                                    <div className="card-body p-5">
                                        <div className="h4 text-center mb-2">Forgot Password</div>
                                        <form action="/" >
                                            <div className="mb-3 field-box">
                                                <label className="text-gray-600 small" for="emailExample">
                                                    Email address
                                                </label>
                                                <div className=" field-otp-box">
                                                    <input className="form-control form-control-solid" type="email" aria-label="Email Address" aria-describedby="emailExample" name="email" onChange={handleInputChange} value={email} />
                                                    <button type="button" className="btn btn-sm btn-warning otp-button" disabled={!email} onClick={handleGetOtp}>
                                                        <span className="text-dark">GET OTP</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="text-gray-600 small" for="emailExample">
                                                    OTP
                                                </label>

                                                <input className="form-control form-control-solid" type="number" aria-label="Email Address" aria-describedby="emailExample" name="otp" onWheel={(e) => e.target.blur()} onChange={handleInputChange} value={OTP} />

                                            </div>
                                            <div className="mb-3 field-box">
                                                <div className="mb-3">
                                                    <label className="text-gray-600 small" for="emailExample">
                                                        Password
                                                    </label>
                                                    <div className=" field-otp-box">
                                                        <input className="form-control form-control-solid" type="text" aria-label="Email Address" aria-describedby="emailExample" name="password" onChange={handleInputChange} value={password} />


                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn btn-block w-100 btn-xl btn-warning btn_admin mt-2 px-2" type="button"
                                                disabled={!email || !OTP || !password}
                                                onClick={() => handleForgotPassword()}> Forgot Password</button>
                                        </form>
                                    </div>
                                    {/*  <hr className="my-0" /> */}
                                    <div className="card-body ">
                                        <div className="small text-center">
                                            <Link className="btn-link text-decoration-none" to="/">Back to - Login</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default ForgetpasswordPage;